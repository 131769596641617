import React from 'react';

import Layout from '../components/Layout';

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import SimilarArticles from '../components/SimilarArticles';
import Seo from '../components/Seo';


const KeywordTemplate = ({ pathContext,path,  ...props }) => {
    if (!pathContext?.id) {
      return null;
    }
    
    return (
      <Layout>
        <Header title="Palavra-Chave" subtitle={pathContext.id} />
        <Seo
          title={
            'Todos os artigos do nosso blog com a etiqueta ' + pathContext.id
          }
          description={pathContext.id}
          keywords={[pathContext.id]}
        />
        <div className="site-container blog-post">
          <div className="container">
            <SimilarArticles keywords={[pathContext.keyword]} />
          </div>
        </div>
        {/* <Pagination currentPage={props.pageContext.currentPage} totalPages={props.pageContext.numPages} uri="/blog" /> */}
        <SocialLinks />
        <Footer />
      </Layout>
    );
};

export default KeywordTemplate;
